import React from 'react';
import Select, { SingleValue } from 'react-select';
import _sortBy from 'lodash/sortBy';

type Props = {
  selectedUser: string;
  onUserSelect: (user: string) => void;
  usersList: string[];
};

type UserOption = {
  value: string;
  label: string;
};

const UsersReportsSelect = ({ selectedUser, onUserSelect, usersList }: Props) => {
  const getUserOptions = () =>
    _sortBy(
      usersList.map((user) => ({
        value: user,
        label: user,
      })),
      'label'
    );

  const emptyOption = { value: '', label: 'Select User...' };
  const userOptionsPlusEmptyOption = [emptyOption, ...getUserOptions()];

  const handleChange = (newValue: SingleValue<UserOption>) => {
    onUserSelect(newValue?.value || '');
  };

  return (
    <div style={{ marginBottom: '25px' }}>
      <div>
        <h4>Select a user to view their reports...</h4>
      </div>
      <div>
        <Select
          options={userOptionsPlusEmptyOption}
          value={{
            value: selectedUser,
            label: selectedUser || 'Select User...',
          }}
          name="userReports"
          isSearchable
          isClearable={false}
          onChange={handleChange}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
    </div>
  );
};

export default UsersReportsSelect;
