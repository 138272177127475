import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import {
  fetchAllReportsForDashboard,
  fetchMinimalReportsForDashboard,
  updateSelectedReportUser,
} from '../../redux/actions';
import DashboardColumns from '../DashboardColumns/DashboardColumns';
import { ReportDashboardSelectors, SavedReportsSelectors } from '../../../core/redux/selectors';
import { ErrorMessage, LoadingMessage } from '../../../../core/index';
import UsersReportsSelect from '../UsersReportsSelect';
import type { ApiSavedReportMinimal } from '../../../types/api';
import type { ReportIdsByStatus } from '../../../types/index';
import type { ErrorMessages } from '../../../../core/types/index';
import type { ReduxState } from '../../../../rootReducer';

type DispatchProps = {
  fetchMinimalReports: typeof fetchMinimalReportsForDashboard;
  fetchReports: typeof fetchAllReportsForDashboard;
  updateSelectedUser: typeof updateSelectedReportUser;
};

type StateProps = {
  errorMessage: ErrorMessages;
  hasErrored: boolean;
  isFetching: boolean;
  reportIds: ReportIdsByStatus;
  usersList: string[];
  reports: Record<string, ApiSavedReportMinimal>;
  selectedUser: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const mapStateToProps = (state: ReduxState): StateProps => ({
  reportIds: ReportDashboardSelectors.getReportIds(state),
  isFetching: SavedReportsSelectors.isFetchingReports(state),
  hasErrored: SavedReportsSelectors.hasFetchReportsErrored(state),
  errorMessage: SavedReportsSelectors.getReportErrorMessage(state),
  usersList: SavedReportsSelectors.getUsersWithReportsList(state),
  reports: SavedReportsSelectors.getSavedReportsDetails(state),
  selectedUser: ReportDashboardSelectors.getSelectedUser(state) || '',
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  fetchReports: bindActionCreators(fetchAllReportsForDashboard, dispatch),
  fetchMinimalReports: bindActionCreators(fetchMinimalReportsForDashboard, dispatch),
  updateSelectedUser: bindActionCreators(updateSelectedReportUser, dispatch),
});

export class ReportDashboard extends PureComponent<Props> {
  componentDidMount() {
    const { fetchMinimalReports, fetchReports } = this.props;
    fetchMinimalReports();
    fetchReports();
  }

  render() {
    const { errorMessage, hasErrored, isFetching, usersList, selectedUser } = this.props;
    const filteredReportIds = this.getFilteredReportIds();

    return (
      <div style={{ width: '100%' }}>
        <div className="col span_12_of_12">
          <h1 className="border-bottom-solid">Report Dashboard</h1>
        </div>
        <div className="col span_12_of_12">
          <LoadingMessage isLoading={isFetching}>
            {hasErrored ? (
              <ErrorMessage errorMessages={errorMessage} />
            ) : (
              <div className="col span_12_of_12">
                <div className="col span_3_of_12">
                  <UsersReportsSelect
                    selectedUser={selectedUser}
                    onUserSelect={this.handleUserSelect}
                    usersList={usersList}
                  />
                </div>
                <div className="col span_12_of_12">
                  <DashboardColumns reportIds={filteredReportIds} />
                </div>
              </div>
            )}
          </LoadingMessage>
        </div>
      </div>
    );
  }

  handleUserSelect = (user: string) => {
    const { updateSelectedUser } = this.props;
    updateSelectedUser(user);
  };

  getFilteredReportIds = (): ReportIdsByStatus => {
    const { reportIds, reports, selectedUser } = this.props;

    if (!selectedUser) {
      return reportIds;
    }

    return Object.entries(reportIds).reduce((filtered, [status, ids]) => {
      const filteredIds = ids.filter((id) => {
        const report = reports[id];
        return report?.Author === selectedUser || report?.Authoriser === selectedUser;
      });
      return {
        ...filtered,
        [status]: filteredIds,
      };
    }, {} as ReportIdsByStatus);
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ReportDashboard);
